<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-custom-object-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "ProjectParameters.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="companyParametersData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="Value" class="form-label required">{{
                $t(
                  "ProjectParameters.Value",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="Value"
                v-model="companyParametersData.value"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "CompanyParameterEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      companyParametersData: {
        publicId: "",
        oldName: "",
      },
    };
  },
  methods: {
    getCompanyParameterDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CompanyParameterGet?id={0}",
            this.$route.params.companyParameterId
          )
        )
        .then((response) => {
          this.companyParametersData = response.data;
          this.companyParametersData.oldName = response.data.name;
          this.companyParametersData.publicId =
            this.$route.params.companyParameterId;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-custom-object-edit");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CompanyParameterEdit", { ...this.companyParametersData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/Companyparameter/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getCompanyParameterDetail();
  },
};
</script>
